<template>
  <SlideYUpTransition :duration="animationDuration">
    <b-modal
      ref="app-modal"
      class="modal fade"
      :size="size"
      :hide-header="!$slots.header"
      :modal-class="[{'modal-mini': type === 'mini'}, ...modalClasses]"
      tabindex="-1"
      role="dialog"
      centered
      :header-class="headerClasses"
      :footer-class="footerClasses"
      :content-class="[gradient ? `bg-gradient-${gradient}` : '', ...modalContentClasses]"
      :body-class="bodyClasses"
      :aria-hidden="!show"
      @mousedown.self="closeModal"
      @close="closeModal"
      @hide="closeModal"
      @ok="accept"
    >
      <p
        v-if="warning"
        class="my-4"
      >
        <b>Warning:</b> {{ warning }}
      </p>
      <p class="my-4">
        {{ body }}
      </p>

      <template v-slot:modal-header>
        <slot name="header" />
        <slot name="close-button">
          <button
            v-if="showClose"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span :aria-hidden="!show">×</span>
          </button>
        </slot>
      </template>

      <slot />

      <template v-slot:modal-footer>
        <slot name="footer" />
      </template>
    </b-modal>
  </SlideYUpTransition>
</template>
<script>
  import { SlideYUpTransition } from "vue2-transitions";

  export default {
    name: "Modal",
    components: {
      SlideYUpTransition
    },
    props: {
      show: Boolean,
      showClose: {
        type: Boolean,
        default: true
      },
      type: {
        type: String,
        default: "",
        validator(value) {
          let acceptedValues = ["", "notice", "mini"];
          return acceptedValues.indexOf(value) !== -1;
        },
        description: 'Modal type (notice|mini|"") '
      },
      modalClasses: {
        type: [Object, String],
        description: "Modal dialog css classes"
      },
      size: {
        type: String,
        description: 'Modal size',
        validator(value) {
          let acceptedValues = ["", "sm", "lg"];
          return acceptedValues.indexOf(value) !== -1;
        },
      },
      modalContentClasses: {
        type: [Object, String],
        description: "Modal dialog content css classes"
      },
      gradient: {
        type: String,
        description: "Modal gradient type (danger, primary etc)"
      },
      headerClasses: {
        type: [Object, String],
        description: "Modal Header css classes"
      },
      bodyClasses: {
        type: [Object, String],
        description: "Modal Body css classes"
      },
      footerClasses: {
        type: [Object, String],
        description: "Modal Footer css classes"
      },
      animationDuration: {
        type: Number,
        default: 500,
        description: "Modal transition duration"
      },
      acceptCallback: {
        type: Function,
        default : () => {},
        description: "Call some callback when the modal is accepted"
      },
      title: {
        type: String,
        description: "Modal title"
      },
      body: {
        type: String,
        description: "Modal body"
      },
      warning: {
        type: String,
        description: "Body waning message",
        default: null
      }
    },
    watch: {
      show(val) {
        if (val) {
          this.$refs['app-modal'].show();
        } else {
          this.$refs['app-modal'].hide();
        }
      }
    },
    methods: {
      closeModal() {
        this.$emit("update:show", false);
        this.$emit("close");
        this.$store.commit('setModalStatus', false)
      },
      accept() {
        // Close modal and call callback and close
        this.closeModal()
        return this.acceptCallback()
      }
    }
  };
</script>
<style>
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
</style>
