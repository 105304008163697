import Vue from 'vue'
import Vuex from 'vuex'
import authenticationModule from './modules/authentication';
import notificationModule from './modules/notification';
import triggersModule from './modules/triggers';
import usersModule from './modules/user';
import sidebarModule from './modules/sidebar';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authentication: authenticationModule,
    triggers: triggersModule,
    users: usersModule,
    sidebar: sidebarModule,
    notification: notificationModule,
  },
  state: {
    isModalOpen: false,
    isDeleteModalOpen: false,
    loading: 0,
  },
  mutations: {
    setModalStatus(state, isOpen) {
      state.isModalOpen = isOpen;
    },
    setDeleteModalStatus(state, isOpen) {
      state.isDeleteModalOpen = isOpen;
    },
    setStatistics(state, statistics) {
      state.statistics = statistics
    },
    addLoadingRequest(state) {
      state.loading++
    },
    removeLoadingRequest(state) {
      state.loading--
    }
  },
  getters: {
    isModalOpen: state => state.isModalOpen,
    isDeleteModalOpen: state => state.isDeleteModalOpen,
    statistics: state => state.statistics,
    loading: state => state.loading,
  },
})
