import axios from 'axios';

const client = axios.create({
  baseURL: window.location.origin,
});

const send = message => client.post('/_logger', { message: JSON.stringify(message) })
  .catch(() => Promise.resolve());


export default {
  send,
};
