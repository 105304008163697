import api from './api';

export default {
  namespaced: true,
  state: {
    triggers: [],
    count: 0,
    trigger: null,
    filters: {}
  },
  mutations: {
    setTriggers(state, triggers) {
      state.triggers = triggers;
    },
    setTriggersNumber(state, count) {
      state.count = count;
    },
    setTrigger(state, trigger) {
      state.trigger = trigger;
    },
    setFilters(state, filters) {
      state.filters = filters
    },
    backupFilters(state, filters) {
      state.filters = filters
    }
  },
  getters: {
    getTriggers: state => state.triggers,
    getTriggersNumber: state => state.count,
    getTrigger: state => state.trigger,
    getFilters: state => state.filters,
  },
  actions: {
    getTriggers(context, data = {}) {
      context.commit('backupFilters', data)
      return api.get(data)
        .then((result) => {
          context.commit('setTriggers', result.data.data);
          context.commit('setTriggersNumber', result.data.count);
        })
    },
    getLatestTriggers(context) {
      const data = {
        page: 1,
        limit: 10,
        sortBy: 'updated_at',
        order: 'desc',
      };

      return context.dispatch('getTriggers', data)
    },
    getTriggerById(context, id) {
      return api.find(id)
        .then((result) => {
          result.data.data.dataParsed = JSON.parse(result.data.data.data);
          return context.commit('setTrigger', result.data.data);
        })
    },
    deleteTrigger: (context, id) => api.del(id),
    updateTrigger(context) {
      const payload = {
        type: context.state.trigger.type,
        data: JSON.stringify(context.state.trigger.dataParsed),
      };

      return api.update(context.state.trigger.id, payload)
    },
    createTrigger(context, data) {
      return api.create(data)
    }
  }
}
