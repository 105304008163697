var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('Notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.hasPermission(_vm.user.role, 'READ'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          path: '/dashboard',
          icon: 'ni ni-tv-2 text-primary',
        }}}):_vm._e(),(_vm.hasPermission(_vm.user.role, 'READ'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Debezium Signals',
          path: '/debezium-signal',
          icon: 'ni ni-planet text-blue'
        }}}):_vm._e(),(_vm.hasPermission(_vm.user.role, 'ADMIN'))?_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          path: '/users',
          icon: 'ni ni-single-02 text-blue'
        }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }