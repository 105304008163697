import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Authentication/AuthLayout';
import NotFound from '@/views/NotFoundPage';

const routes = [
  {
    path: '/dashboard',
    redirect: 'dashboard',
    meta: {
      public: false,
      role: 'READ'
    },
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          public: false,
          role: 'READ'
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue')
      },
      {
        path: '/users',
        name: 'Users',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserList')
      },
      {
        path: '/debezium-signal',
        name: 'Debezium Signal',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Triggers/TriggerList')
      },
      {
        path: '/debezium-signal/new',
        name: 'Create Debezium Signal',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Triggers/TriggerCreate')
      },
      {
        path: '/debezium-signal/:id',
        name: 'Debezium Signal',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Triggers/TriggerDetail')
      },
      {
        path: '/debezium-signal/:id/edit',
        name: 'Debezium Signal Edit',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Triggers/TriggerEdit')
      },
      {
        path: '/users/:id',
        name: 'User detail',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserDetail')
      },
      {
        path: '/users/:id/edit',
        name: 'Edit user',
        meta: {
          public: false,
          role: 'ADMIN'
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/User/UserEdit')
      },
      {
        path: '*',
        component: NotFound,
        meta: {
          public: true
        },
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    meta: {
      public: true
    },
    component: AuthLayout,
    children: [
      {
        path: '/',
        name: 'login',
        meta: {
          public: true
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Authentication/Login.vue')
      },
      {
        path: '/login/:type',
        name: 'Login Handler',
        meta: {
          public: true
        },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Authentication/Processing')
      },
      {
        path: '*',
        component: NotFound,
        meta: {
          public: true
        },
      }
    ]
  },
  {
    path: '/notfound',
    component: NotFound,
    meta: {
      public: true
    },
  }
];

export default routes;
