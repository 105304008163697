export default {
  namespaced: true,
  state: {
    state: [],
    settings: {
      overlap: false,
      verticalAlign: 'top',
      horizontalAlign: 'right',
      type: 'info',
      timeout: 3000,
      closeOnClick: true,
      showClose: true
    },
    notifications: []
  },
  mutations: {
    addNotification(context, notification) {
      context.state.push(notification)
    },
    setOptions(state, options) {
      state.settings = Object.assign(state.settings, options)
    },
    removeNotification(state, timestamp) {
      const indexToDelete = state.state.findIndex(n => n.timestamp === timestamp);
      if (indexToDelete !== -1) {
        state.state.splice(indexToDelete, 1);
      }
    },
    acknowledge(state, key) {
      const indexToDelete = state.notifications.findIndex(n => n.key === key);

      if (indexToDelete !== -1) {
        state.notifications.splice(indexToDelete, 1);
      }
    },
    acknowledgeAll(state) {
      state.notifications = []
    }
  },
  getters: {
    state: state => state.state,
    settings: state => state.settings,
    notifications: state => state.notifications,
  },
  actions: {
    addNotification(context, notification) {
      if (typeof notification === 'string' || notification instanceof String) {
        notification = { message: notification };
      }
      notification.timestamp = new Date();
      notification.timestamp.setMilliseconds(
        notification.timestamp.getMilliseconds() + context.state.state.length
      );
      notification = Object.assign({}, context.state.settings, notification);
      context.commit('addNotification', notification)
    },
    notify(context, notification) {
      if (Array.isArray(notification)) {
        const promises = []

        notification.forEach(notificationInstance => {
          promises.push(context.dispatch('addNotification', notificationInstance))
        })

        return Promise.all(promises)
      }

      return context.dispatch('addNotification', notification)
    }
  }
}
