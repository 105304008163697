<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand"
    :class="{'navbar-dark': type === 'default'}"
  >
    <p
      class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
    >
      {{ $route.name }}
    </p>
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a
          slot="title-container"
          href="#"
          class="nav-link pr-0"
          @click.prevent
        >
          <b-media
            no-body
            class="align-items-center"
          >
            <span class="avatar avatar-sm rounded-circle">
              <img
                alt="Image placeholder"
                :src="user.metadata.picture"
              >
            </span>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{ user.metadata.name }}</span>
            </b-media-body>
          </b-media>
        </a>

        <template>
          <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">
              Welcome!
            </h6>
          </b-dropdown-header>
          <b-dropdown-item @click="logout">
            <i class="ni ni-user-run" />
            <span>Logout</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components';
import {mapGetters} from 'vuex';

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      notifications: [
        {
          title: 'SUper test',
          link: 'wtf'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: 'authentication/getUser'
    })
  },
  methods: {
    logout() {
      localStorage.removeItem('token')
      return this.$router.push('/')
    }
  }
};
</script>

<style>
.add-pointer {
  cursor: pointer;
}

.add-space {
  margin-left: 3px;
}
</style>
